body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.lname {
  text-transform: uppercase;
  font-size: 42px;
  color: #ff0059;
}

.lectures {
  word-spacing: 18px;
  margin-bottom: 40px;
}

.lectures a {
  color: #ff0059;
  margin: 0 25px;
}

.lectures img {
  max-width: 300px;
}

.lecturerImg {
  margin-left: 20px;
  margin-bottom: 10px;
  float: right;
}

.bioSection {
  margin: 0px;
}

.mgn-r-30 {
  margin-right: 30px;
}

.mgn-t {
  margin-top: -70px;
}

.imgi2 {
  margin-top: -80px;
  max-height: 360px;
}

.videoContainer {
  display: flex;
  justify-content: space-around;
}

.imgi1 {
  max-height: 220px;
}

.bulkaPicDesc {
  font-size: 0.7rem;
  word-spacing: 0.5rem;
  margin-top: 2rem;
  text-align: center;
}

.kostuk-project {
  word-spacing: 1px;
  margin-bottom: 0 !important ;
  margin-top: 10px !important ;
}

iframe {
  margin: 20px;
}
