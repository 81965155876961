@import "./ticker.css";

@font-face {
  font-family: "Helmet Neue";
  src: url("HelmetNeue-Regular.woff2") format("woff2"),
    url("HelmetNeue-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

* {
  font-family: "Helmet Neue", serif !important;
}

.App {
  min-height: 100vh;
  width: 100%;
  background-color: black;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

#header img {
  cursor: pointer;
}

#guide-wrapper {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  align-items: center;
}

.patch-instruction h2 {
  margin-bottom: 0px;
}

.patch-instruction {
  color: rgba(182, 182, 182, 1);
  font-size: 28px;
  white-space: pre-wrap;
}

#guide {
  background: rgba(0, 0, 0, 0.8);
  color: rgba(182, 182, 182, 1);
  font-size: 20px;
  border-radius: 10px;
  max-width: 700px;
  max-height: 570px;
  padding: 80px 58px;
  z-index: 100;
}

#cross-icon {
  position: absolute;
  top: -60px;
  right: 0;
  cursor: pointer;
}

@keyframes changeBg {
  0% {
    background-image: url("img/AIR.png");
  }
  25% {
    background-image: url("img/COVID.png");
  }
  50% {
    background-image: url("img/WEATHER.png");
  }
  95% {
    background-image: url("img/TEMP.png");
  }
  100% {
    background-image: url("img/AIR.png");
  }
}

.empty-state {
  font-size: 36px;
  color: white;
  width: 60%;
  margin: auto;
  text-align: CENTER;
}

#bg {
  pointer-events: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  animation: changeBg 10s infinite linear;
}

.selected {
  border-bottom: 3px solid #ff0059;
  width: max-content;
}

span.disabled,
span.disabled:hover {
  border: none;
  cursor: not-allowed;
  color: rgb(56, 56, 56) !important;
}

.selected:hover {
  color: white !important;
}

#download {
  fill: #ff0059;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

#data-nav span:hover {
  color: #ff0059;
}

.container {
  width: 80%;
  position: relative;
}

.data-container {
  display: flex;
  height: 100%;
}

#header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#header * {
  z-index: 1;
}

.main,
#data {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 400px;
  max-height: 80%;
  position: relative;
}

.main {
  height: 100%;
  justify-content: center;
  align-items: center;
}

.main .main-title {
  color: white;
  font-size: 100px;
  line-height: 111px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  letter-spacing: 5px;
  font-weight: normal;
  transform: translateY(-100px);
}

.buttons-wrapper {
  display: flex;
}

#info {
  width: 40%;
  margin: 70px 30px 30px 30px;
  display: flex;
  flex-direction: column;
}

#info .title {
  font-size: 40px;
  color: white;
  margin-bottom: 20px;
  z-index: 11;
}

#info .description {
  color: #555555;
  font-size: 24px;
  z-index: 11;
}

#vizualisation {
  width: 60%;
}

#data-nav {
  width: 50%;
  margin-left: 30px;
  flex-wrap: wrap;
}

#data-nav span {
  margin-right: 20px;
  margin-bottom: 10px;
}

button.download,
button.osc {
  font-size: 18px;
  display: flex;
  align-items: center;
  color: white;
  background: transparent;
  border: 0;
  cursor: pointer;
}
button.download:focus,
button.osc:focus {
  outline: none;
}

button.download span {
  margin-left: 10px;
}

iframe #details {
  display: none;
}

.infolayer {
  display: none;
}

::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

#scene {
  height: 100% !important;
  width: 100% !important;
  top: 0 !important;
  left: 0 !important;
}

.download-dropdown {
  position: absolute;
  color: white;
  width: max-content;
  right: 0;
  z-index: 10;
  top: 40px;
  background: #000000d4;
  padding: 20px;
  border-radius: 15px;
}

.download-dropdown-item {
  padding: 10px 0;
  cursor: pointer;
}

.download-dropdown-item:hover {
  color: #ff0059;
}

.overlay {
  border: 2px solid white;
}

.invert-hover {
  padding: 10px;
  cursor: pointer;
  display: flex;
  width: fit-content;
}

.invert-hover:hover {
  background-color: white;
  color: black;
}

nav {
  width: 35%;
  display: flex;
  justify-content: space-between;
}
nav span {
  color: #b6b6b6;
  font-size: 20px;
  cursor: pointer;
}

#pages-nav {
  flex-wrap: wrap;
}

#pages-nav span {
  color: #808080;
  font-size: 20px;
  cursor: not-allowed;
  margin-right: 15px;
  margin-bottom: 15px;
}

#pages-nav span.selected,
nav span.selected {
  color: white;
  cursor: pointer;
}

#pages-nav span.available {
  cursor: pointer;
  color: white;
}

.panel {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 11;
}

span.country-item {
  font-size: 18px;
  line-height: 2;
  cursor: pointer;
  margin: 10px;
}

.main-svg {
  background: transparent !important;
}

.modebar-container * {
  display: none;
}

.modebar-container a[data-title="Download plot as a png"] {
  opacity: 0;
}

.modebar-container a[data-title="Download plot as a png"]:hover {
  pointer-events: none;
  cursor: initial;
}

input {
  background: transparent;
  border: 0;
  color: white;
  line-height: 2;
  font-size: 18px;
  width: 100%;
}

footer {
  border-top: 1px solid white;
  width: 100%;
  position: absolute;
  bottom: 0;
  margin: 0 30px;
  background-color: transparent;
  display: flex;
  align-items: center;
  height: 80px;
}

#house-of-europe-logo {
  height: 100%;
  margin: 0 20px;
  cursor: pointer;
}

#moving-forward-logo {
  height: 70%;
  cursor: pointer;
}

input:focus {
  outline: none;
}

.bioContainer {
  display: flex;
}
