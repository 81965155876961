@-webkit-keyframes ticker {
	0% {
	  -webkit-transform: translate3d(50%, 0, 0);
	  transform: translate3d(50%, 0, 0);
	  visibility: visible;
	}
  
	100% {
	  -webkit-transform: translate3d(-100%, 0, 0);
	  transform: translate3d(-100%, 0, 0);
	}
  }
  
  @keyframes ticker {
	0% {
	  -webkit-transform: translate3d(50%, 0, 0);
	  transform: translate3d(50%, 0, 0);
	  visibility: visible;
	}
  
	100% {
	  -webkit-transform: translate3d(-100%, 0, 0);
	  transform: translate3d(-100%, 0, 0);
	}
  }

  .ticker-wrap {
	position: fixed;
	bottom: 0;
	width: 100%;
	overflow: hidden;
	height: 4rem;
	/*padding-left: 100%;*/
	box-sizing: content-box;
}

	.ticker {
	color: whitesmoke;
	text-shadow: 0px 1px 1px #FF0059, 0px 0px 4px #FFFFFF;
	text-transform: uppercase;
	font-size: 28px;
    display: inline-block;
    height: 4rem;
    line-height: 4rem;  
    white-space: nowrap;
    /*padding-right: 100%;*/
    box-sizing: content-box;

    -webkit-animation-iteration-count: infinite; 
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
   -webkit-animation-name: ticker;
           animation-name: ticker;
    -webkit-animation-duration: 30s;
			animation-duration: 30s;
}

.ticker div{
	display: flex;
	align-items: center;
	height: 100%;
}